// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/components/kit/core/mixins.scss';
// import KIT mixins
@import 'src/components/cleanui/styles/mixins.scss'; // import CLEANUI mixins
$assetPath: '/RetroDCS.System/Icons/';

.dx-datagrid-group-closed::before {
  content: "\f04e";
  font-size: 18px;
  color: red;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
  background-color: #e0e0e0;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit {
  white-space: normal !important;
}

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form)
.dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
  background: #ccc;
}

.rnc {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 255px;
  background-color: #eee;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 10px 0;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 10px 6px;
  font-size: inherit;
  font-family: inherit;
}


.dx-button-mode-contained.dx-button-warning {
  background: #e48b0d !important;
}

.floatRight {
  float: right !important;
  margin-left: 5px !important;
}

.denyFlexEndForFooter {
  justify-content: flex-start !important;
}

.dx-datagrid-headers {
  color: #222 !important;
  font-weight: 400;
}

.input-group-header {
  font-size: 14px;
  color: #0c0c1b !important;
  font-weight: 500;
}

.dx-icon-custom-example {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAJnRFWHRUaXRsZQBFeGFtcGxlO1NhbXBsZTtCbGFjaztib2FyZDtmcmFtZdkVcjQAAAGfSURBVHhepZM/ihRREMZ/1T2ricfYzMhQxlkE9QpGwh7BK3gFNxME0UiPIGoi6wgewFgMDEz8M8xu976q+pQummEXQcQPiqrqB7/6XnW3AXtABxjn9Nc+gGbrR4dvL/fdSgIhTEIA1c8FEnVm1Y/N3x3cf3F7sYdW1+49AAnIKaTEVBkSFFRULYIPTx8ugcUiwtHwmZt3H19wqqoRpaoNeH20j9wBuoU8kJyM4PrqFgBip0LssOvjN5CNiAbAItwhHI/G6ThgOwKyyqaqDQj3GtgCwCYHyKeD7c8Nfd/XxBqNzObFEpmEN5RBegOwcpBnuDe+ff9B39vv6DDr6TrIUK3Wg1RMAKKR7gVIbygKMA7b3WgqGwbSfAWaOygmIOUgIEfCnXE8BZ1fmmoBGJrgEWeQQYYXQFGWPBrDcML61TNmLe8cclHujtKJGZC1xIn49csn9q8eoNn6H19qaXcFdxQjH58vEfMXKFBWIMjKUM+kICMAtNhsx+OXR09uzIOEQFQgKomSKLixGfw9MBpwBbgEdP/wNwoYga1J4n/0C5/XQvBeCp+MAAAAAElFTkSuQmCC') no-repeat center !important;
}

.dx-icon-custom-open {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACIklEQVR4XqWTvWtUURDFf2/zJCqptBIUSz+xUAyiBmP8AyxsRLCwEAQRlBR2FjZilYBaiKBYiY2NEGxETWxEEjQmEglZNrgxb9n48rFfb9+9d0a5762CAZscuBxmmDlzZuAGqspGEACFnMlZ8yf8B53B4cTDgdeFgH6yGBWoJ+ZC342x5+MPTouioMofEqX3+igdhIj2H7r8lAyKbdWYenbz3p1L+0aPXH1TZT0EcLlLQmcE3Cqk31FxhDgOnru23Znh8qkD20ByuwqiYIwbG7j14QxgMgEroA5cQiAW1NIlDQ5fHCTo3gEEoAIIqo7Ru1f6gK6/AqnLBGyC2iZiW+AM1MuozxtU8hoVVqt1gJ4gCAqAC97dPqZ9g0PIyicq0+PECxVUQEUQyS6nooiI56X5KurTijithiZ1tOKIZKHIctRg//lhvHhuG3GoWhCLOouKAUmJS994+2ho3q+wWi4Rz8zSs6sXTSIasy9R35DdR0V8jHN+HRFHabLMypoZCU3bsVSaYy1aZufxvbQr09ha3CnMmlTATxfECwlRscKXcv1FmLYtS3NzdHf3sHlrSH1mAttY9tY7LjRzkecctZU28c/G1/vvo2LYaqSwGLHnxEmSxSmSatFPUL97p0m9CCpeqLqQENfNCGDCRjP9iKsdnRx5xWR+XXJWAAFQVAFVRCG1Wv78o/kYSANgC7AJKLAewT+xAgZInpzd7QA2/J1/Afq2oUsi7mIKAAAAAElFTkSuQmCC') no-repeat center !important;
}

.dx-icon-custom-up {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAD3RFWHRUaXRsZQB1cDtmb2xkZXIDEYqgAAAC9klEQVR4XqWMX2gcVRSHv5mdxE13pAr+CW2woMbuQ2mkoSkSTUKkSCRYJS8qtdRUsPjQUoRalYLSF8EKFrJRtJFSijRBq0LRQluUFARrExtj18Qmi0k2Zg3ZXbebzM7MnXuPJdsX6aMffOd3Dgd+iMhtArza31A11QBg7flw/Uvdh93dgN311hq2vxGn8/U4uK5r3zR2S+dW2q/0rWN06jsA6/kj9zUeON5U3nX0wZX2vXUbAWvk+lnaD8RxLh/bdtG26UAAQAys+PrFjkNXBl97CpNsW1N7R8I+uf3RXjfSIf35d0/dvzHW1tzY7bftqxUrfbxTkrtPUEXQQZnrQ2/mf5yobBn6xS7c3T56+ImmZw8++dgWlF7h3PdjnL305fvDfZW3gcga/6hDNu3pgzCLGI1lGSIVJ5eeZro4yzd/n+flrr0E8TGMCJb3MKnTA+a3XyeeHjkVXnB0ZEA06ADLRCARsdhaEskWvhhK8UzrLpbMeezAWS0I9A/0dO6w5+ZnBza0Ri2OUbpaEPmgPYzy0PEGjn11iE0PtFFJDON7C4ANGEIdUZMo0t3as/6TmZOfWpffe1y27vsAKY2Rn7jCP/OLJJI9vPB5LyKseufaGL07WygHRQYH/6BU1ED156hQUynkCBcylHLLPPTcEcS5l3PvTKONwq9U2NnfTKB8Qh0iIpzZfxFL+WSvjsw6OtCU/5qhNDlFoqEJCUv4v58G0WAM9l2taCP4KkBF0eruBDmyFwZIj86dcJTS5GcyeIsF6ps3EObG0StFRGuMaCw3YJ3TyMepaxhgc30SS4UsTS9ybd4746ggopDJUFcbp8bR+HNX0d4NEI2YiBp3gaM7DqJUBGJwYjZq6U8KxUo69VM+41S8EMnluGfzI/gLaYJ8FrRBjEaMQZW/Roys3hYGJcLs1DL5lehbQDnecvQz6sbWyUsjTA6DiIBUUwAMAIhUhxFQkWTHc5XPgNByXbcOqAFsbsfivwigAH+gq14DWCLC/+Ffh1K04OSBYuYAAAAASUVORK5CYII=') no-repeat center !important;
}

.dx-icon-custom-info {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADOklEQVR4Xl2PfUxVdRzGP79zDvcNmVedUwZIDMFmE+RmonCRLXpZK1z9o1tLSLG2JAr15nAzbY3abItaI5UCbG0oxT+iXKRpRWAQtpFJ0dtSCCl3rZgv8eK95/ft7Gy11rM923fP8/38XlRJ5DT/VWL25krT438aVKkyzFUAYie+teMzg7diP7eMfLh3BJhzOAEgvLubJw5/DaCKd56KhPf0zJTX90tt6zfSEB2XN6JjsrN1RMrrz0k4cnpuTVXzK8B8wAxHuqF4Vzd3b2v2Fu+K9tz34qfS0H1ZOoauSfPZcdl79LzUtZ6Xd8+OuVmDc1iZs1O4o30gJfXONMAEUOueP/nWAwf65L2+q9I+GJP2gZjUHvlcvp+YEkdSc6jfzY87bun9Tcr2fyYFTzYdA4JW4Y6OPI/HU12Sn45lKuJaAIUWYUV6EADR2s0V4LVMSgsyODM9vTlzw/Y2SxvWdm9ysrF0gZ+4DS6OkJWRSnVjPyKanKw0EjaAuF4a9OOdN89ISc/bagnGg76An4QGlAAwNDzqgiLiGC5+d5nitfm4AnfXn+wnKbBovSFaZ/qSfcRuxrEF11rbvPlMCQefKqap9l601tgi//bXbsXxen0oM2mh4SyrgMfil6k5ZhOQEIWtcZ7fCyIkWYYLO7nr6bgw9sccgYCFRgzDtvWkx3IgUfxwdZobMzahUB5aC3Puv3BnW+N2P8ZmAIVHCfHZ6Sl1V+XRloI1edsCwUWAgFL8fmkU04DXqoocUNjTfA7nIpbk5OMK4a+pPxn6uOcjsjbWF4UjXXbdiUl59oMJqW6/Io++FJX/65F9nW5f47iuc1LCu7v04tWPVSjAl/t40zsbSgu3ZCxL5fqMzaWLw4gIAqAFLQJAbuge5vtNxsd/5ZOuU2fGuvZvtYDbk71vR/owsleHVhWFCpYTXF/IbBzitgYUSabCl6TwWjB84Se+HPhidLK3MQJcY0XlcQATWHjHxldfX/dc5+2qI1/JgZMT0jhwQxoHr7tz1eELUlhzIpFW9sL7wHLA67K5FcdcAwaQsnjtlvszyw92ZG9uGs+tbJOcijbJ3nToyrKHXo4uWPnwJmAJYP3D/Q15J7tsJviPJAAAAABJRU5ErkJggg==') no-repeat center !important;
}

.dx-icon-custom-loadFrom {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHXRFWHRUaXRsZQBMb2FkO1NlbGVjdDtGb2xkZXI7T3BlbpYzPngAAAKrSURBVHhepZNPaFxVFMZ/b/ImpNSm1AhCDdVu0lYLCpoWQ0ntQtxIaSsuqnTpRpBSGqREKkFB3QUitLaolBBNK6btQmM3mkj+LBLUjNMmaGZihk4y00lmkklm5v299/TxZhIIZOeBj8M5nO/Hx4VriAjblWEY265rfYspAtTVZNZ6BKCr/8/QVJujnb0T8uE3oz+e++jrZ2o7iF1rH4pfb5f4tar+vtou491tZzfA73cPnjx/ZXji/NUR94MrI/JTPCcd10cL57p+eBeoM9Hy2gvvfbeZzLfWmOnv+PKrzlMTQ3KmtX5HY/+Jthae3fckdZEIWQtOv7lrz8ef/dcHDJjK06CK4D5EtMJEceitC027p9OJ+2M72f/c0/QOjJNZWAoAJidOHePewK+rhcXUJUBHlK9BFCgbQ1ngV6jTeZqeP8r8Mty9O8Q7rzZy6/IRypbN7L07JEZuHk/88vkNwDOVq6oA30YCs/YtUB5Esxze6/D64Sc4cqCEGfX47dNmUn/M0t8znANMAGP0k1Zpu9iDXp1iaXqSlYVsyGtseQPH8TGNCJXFKZxiGtHCcmoJEQIJWsmS6bkKq5DFXphjNbvOgbe/wAhMaI2IB9pD1MuBXMR3AtmhCqkk49/3pUzlKIrpeVb++ZddzS8hTpFy4mdE+4iqvo9oHc4ohWiFDpSOBZ6iPxgmWJ5PUsrk2fvKfuxMDH+9sHkYmkSD8kOQDkGaXPIR8XTptuk6PvlkgoZoA/WmTyX1F8paA602U0g1RW2nKK3YFArWdM9Ybs60yi6SyfDUiwexFh/g5B+C0uGhyIZJQgiiQ1AuXSJf8gYBzyyXvUn8tdaZ3yeZGQYRAal2AdAAggggghZwfUnHFivfAq4B7ACiQGTb37e1BPAAu/dkswIIv/P/qcfgubQiqqkMrgAAAABJRU5ErkJggg==') no-repeat center !important;
}

.dx-icon-custom-nameManager {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACCklEQVR4Xn1QTUhUURT+rk5iuYgMXARRm2jZMmhpuJFCE4KibRDVqh/pD8dGKG0RVEKrKKjATVCDUG0MY8BqaFGRMaHREERa6QzWpO/de8+Jc+99PZCZPt7hcA/nfD9PMTNW4/bY1IRl7iRiSLF0tuDwtkTPTh7p2g2BEKwmKb4p8/8wPPqYk7sMAkbvTIItO+XCq1k8fzEDdmqpcm05wuVz+xBHGgEpQRRpWersP9qFRrgw8tDvxqY+gSgJLl4dB1nJTUlmDJzoQbTilWNTz0GsQZYg6D/WDUhGuOZgid2OQMfUMAIEAyMPQBQcBCeXzh9ApDWeTLyDNuZpfYIglz3dB5FPHfg/HscWY/miunvjMBKo4rVugEiOs/mvu3LlWgeI3EE4TvuW1jn0dBQc2VLlD/Zcf60ypA2YKWub23On+mRzHo2xBpm2/WjbuhPjZ72LjNU2Sy3tualHk9iw/D61Jpppd1i7aTs2957B9P1hGG383vStXt6297hfVVJNUGhyb1YKSmbwc+mFm1ew8LkEHVkcvPdRZaw2+F3Ku1yQgs+vQP4Z5gzC+h2H8GPmgyMymv5FAFmxQ/JJ9weBCEkpBsc1sGUsfq+iWlkZ8gTGgK12Syo5hHeihAsU5gCZX/j5rYrF+aWh5nWtg45grryAT2+/QOAdhBi+BbIkykuUZiuiPLixBQ5/AR3enQWvrLN5AAAAAElFTkSuQmCC') no-repeat center !important;
}

.dx-icon-custom-article {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAB10RVh0VGl0bGUAQXJ0aWNsZTtMb2FkO09wZW47SXNzdWXCBs/VAAAAa0lEQVR4XtWTwQmAQAwE0+A2YwmSoizAeuwgxAgSRF1PjR8Dw71m2M+Ju5dIQVX9AeeBqzOz5TkGQkSQAfQDgwY8A42jgX8uCG4vwCokrQCCFKaxQ+AE7AMgAmUboHJzwVs5EKnIC5/8xhIz2uLi3MduvyMAAAAASUVORK5CYII=') no-repeat center !important;
}

.buttonEdit .dx-button-content {
  padding: 0 2px;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 5px !important;
}

[data-kit-theme="default"] .ant-menu {
  font-size: 13px !important;
}


[data-kit-theme='default'] .ant-menu-item > div {
  font-size: 13px !important;
}

.pivotgrid-row-selected {
  background-color: #d1e0ed !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: #d1e0ed !important;
  color: #333;
}

.dx-pivotgrid .dx-pivotgrid-vertical-headers td.dx-pivotgrid-expanded span {
  max-width: 180px;
  overflow: hidden;
}

.dx-datagrid .dx-header-row > td {
  word-wrap: normal;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
  width: 10px;
  min-width: 10px;
  max-width: 10px;
}

.dx-toolbar .dx-toolbar-label {
  font-size: 13px;
}

.nav-tabs .nav-link.active {
  background: #747477 !important;
}

.dx-widget {
  font-family: 'Roboto', 'Segoe UI', helvetica, verdana, sans-serif !important;
  font-weight: 600 !important;
  // font-family: 'poppins', sans-serif !important;
}

body {
  font-family: 'Roboto', 'Segoe UI', helvetica, verdana, sans-serif;
  // font-family: 'poppins', sans-serif !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  display: inline-block;
  padding: 5px;
  font-size: 13px;
  line-height: normal;
  white-space: nowrap;
}

.dx-fieldset {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: #8a8a8e;
  margin: 5px 0;
  padding: 0;
}

.customDropDownBox {
  position: relative;
  flex: 1 1 auto !important;
  width: 1% !important;
  min-width: 0 !important;
  margin-bottom: 0 !important;
}

.mandatory {
  color: red !important;
  font-weight: bold !important;
}

.dx-item.dx-radiobutton {
  margin-top: 4px;
}

.dx-fieldset-header {
  padding-left: 0.75rem;
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 500;
}

.dx-texteditor.dx-state-readonly {
  border-style: solid;
}

// $text: #000;
// $success: green;
.hidden {
  display: none;
}

.dx-datagrid .customCellTemplate {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAQUlEQVQ4jWNgGHAgLSQtQwlmUJSW76EED3QAUAMoSsuFUIIZVOQUQynBAx0A1AAKsvJ9lGAGWRERKUrwQAcAAwMATv8/oWpUB0AAAAAASUVORK5CYII=) no-repeat center !important;
  padding: 5px 5px 5px 5px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.dx-treelist .customCellTemplate {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAQUlEQVQ4jWNgGHAgLSQtQwlmUJSW76EED3QAUAMoSsuFUIIZVOQUQynBAx0A1AAKsvJ9lGAGWRERKUrwQAcAAwMATv8/oWpUB0AAAAAASUVORK5CYII=) no-repeat center !important;
  padding: 5px 5px 5px 5px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.customColumn {
  max-width: 50px !important;
}

.dx-field {
  padding-bottom: 10px !important;
}

.input-group {
  padding-bottom: 5px;
}

.modal-header {
  background-color: #0c0c1b !important;
}

.modal-title {
  color: white !important;
}

h2 h4 h5.modal-title {
  color: white !important;
}

.modal-footer {
  border-top: none !important;
}

[data-kit-theme="default"] .ant-menu-horizontal > .ant-menu-item a {
  color: white !important;
}

[data-kit-theme='default'] .ant-dropdown-menu-submenu-title > a {
  color: white !important;
}

[data-kit-theme='default'] .ant-menu-item > a {
  font-size: 13px !important;
  color: white;
}

[data-kit-theme='default'] .ant-dropdown-menu-item > a,
[data-kit-theme='default'] .ant-dropdown-menu-submenu-title > a {
  color: white !important;
  font-size: 13px !important;
}

.modal .modal-header .close {
  color: white;
  background: transparent !important;
}

.input-group-text {
  align-items: left;
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 1 !important;
  color: #444 !important;
  text-align: left;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 !important;
  height: 30px;
  font-size: 13px !important;
  line-height: 1.333 !important;
  min-height: none !important;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-card-body {
  padding: 15px !important;
}

.dx-datagrid .customCellTemplate {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAQUlEQVQ4jWNgGHAgLSQtQwlmUJSW76EED3QAUAMoSsuFUIIZVOQUQynBAx0A1AAKsvJ9lGAGWRERKUrwQAcAAwMATv8/oWpUB0AAAAAASUVORK5CYII=) no-repeat center !important;
  padding: 5px 5px 5px 5px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.dx-treelist .customCellTemplate {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAQUlEQVQ4jWNgGHAgLSQtQwlmUJSW76EED3QAUAMoSsuFUIIZVOQUQynBAx0A1AAKsvJ9lGAGWRERKUrwQAcAAwMATv8/oWpUB0AAAAAASUVORK5CYII=) no-repeat center !important;
  padding: 5px 5px 5px 5px;
  width: 16px;
  height: 16px;
  display: inline-block;
}

//Dashboard Widgets
.navy-bg, .bg-primary {
  background-color: #1ab394 !important;
  color: #ffffff;
}

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

h4.font-bold {
  color: white !important
}

.bg1 {
  background-color: #965387 !important;
  color: #ffffff;
}

.bg2 {
  background-color: #584f84 !important;
  color: #ffffff;
}

.bg3 {
  background-color: #4f8484 !important;
  color: #ffffff;
}

.bg4 {
  background-color: #23c6c8 !important;
  color: #ffffff;
}

.bg5 {
  background-color: #5970f8 !important;
  color: #ffffff;
}

span.dx-button-text {
  background: none;
  margin-left: 0;
  font-size: 14px;
  border-radius: 0;
  font-weight: normal;
  padding: 0;
}

li.nav-item.dashboardTabCss {
  background: white;
}

.tab-wrap .nav-tabs li .nav-link.active span:after {
  background-color: #7283a1;
  width: 100%;
}

.no-right-padding {
  padding-right: 0 !important;
}
